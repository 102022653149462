<template>
    <div class=" ">
        <el-card class="card_box">

            <div class="add_main">

                <div class="margin_top_20">
                    <el-form label-position="right" label-width="140px" :model="formData" :rules="rule" ref="formData">
                        <el-form-item label="LOGO" prop="logo">
                            <el-upload class="upload-demo" action="" :show-file-list="false"
                                :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload"
                                :http-request="uploadImageavatar">
                                <img v-if="formData.logo" :src="formData.logo" class="logo">
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>

                            </el-upload>
                        </el-form-item>
                        <el-form-item label="app名称" prop="name">
                            <el-input v-model="formData.name"></el-input>
                        </el-form-item>

                        <el-form-item label="app版本" prop="version">
                            <el-input v-model="formData.version"></el-input>
                        </el-form-item>
                        <el-form-item label="媒体合作" prop="media_cooperation">
                            <el-input v-model="formData.media_cooperation"></el-input>
                        </el-form-item>
                        <el-form-item label="市场合作" prop="market_cooperation">
                            <el-input v-model="formData.market_cooperation"></el-input>
                        </el-form-item>
                        <el-form-item label="《隐私政策》" prop="privacy_policy">
                            <el-input v-model="formData.privacy_policy"></el-input>
                        </el-form-item>
                        <el-form-item label="《服务条款》" prop="term_of_service">
                            <el-input v-model="formData.term_of_service"></el-input>
                        </el-form-item>




                        <el-form-item label="《免责声明》" prop="disclaimers">
                            <el-input v-model="formData.disclaimers"></el-input>
                        </el-form-item>
                        <el-form-item label="Twitter" prop="twitter">
                            <el-input v-model="formData.twitter"></el-input>
                        </el-form-item>
                        <el-form-item label="Telegram" prop="telegram">
                            <el-input v-model="formData.telegram"></el-input>
                        </el-form-item>
                        <el-form-item label="官网" prop="official_website">
                            <el-input v-model="formData.official_website"></el-input>
                        </el-form-item>
                        <el-form-item label="邮箱" prop="gmail">
                            <el-input v-model="formData.gmail"></el-input>
                        </el-form-item>
                        <el-form-item label="《注销协议》" prop="log_off">
                            <el-input v-model="formData.log_off"></el-input>
                        </el-form-item>
                        <el-form-item label="广告合作" prop="advertising_cooperation">
                            <el-input v-model="formData.advertising_cooperation"></el-input>
                        </el-form-item>
                        <el-form-item label="app简介" prop="desc">
                            <el-input v-model="formData.desc"></el-input>
                        </el-form-item>


                        <el-form-item label="iosApp版本" prop="ios_version_code">
                            <el-input v-model="formData.ios_version_code"></el-input>
                        </el-form-item>
                        <el-form-item label="iosApp名称" prop="ios_version_name">
                            <el-input v-model="formData.ios_version_name"></el-input>
                        </el-form-item>
                        <el-form-item label="ios强制更新" prop="ios_forced">

                            <el-select v-model="formData.ios_forced" placeholder="请选择性别">
                                <el-option label="是" value="1">
                                </el-option>
                                <el-option label="否" value="2">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="ios下载链接" prop="ios_download_url">
                            <el-input v-model="formData.ios_download_url"></el-input>
                        </el-form-item>
                        <el-form-item label="ios更新内容" prop="ios_update_content">
                            <el-input v-model="formData.ios_update_content"></el-input>
                        </el-form-item>
                        <el-form-item label="androidApp版本" prop="android_version_code">
                            <el-input v-model="formData.android_version_code"></el-input>
                        </el-form-item>
                        <el-form-item label="androidApp名称" prop="android_version_name">
                            <el-input v-model="formData.android_version_name"></el-input>
                        </el-form-item>


                        <el-form-item label="android强制更新" prop="android_forced">

                            <el-select v-model="formData.android_forced" placeholder="请选择性别">
                                <el-option label="是" value="1">
                                </el-option>
                                <el-option label="否" value="2">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="android下载链接" prop="android_download_url">
                            <el-input v-model="formData.android_download_url"></el-input>
                        </el-form-item>

                        <el-form-item label="android更新内容" prop="android_update_content">
                            <el-input v-model="formData.android_update_content"></el-input>
                        </el-form-item>



                        <el-form-item>
                            <div class="flex justify_content_c align_items_c">
                                <el-button type="primary" class="custom_btn_width_100" @click="submit('formData')"
                                    v-if="$route.query.type!='detail'">保存</el-button>
                                <el-button class="custom_btn_width_100 " @click="$router.back()">返回</el-button>
                            </div>
                        </el-form-item>
                    </el-form>
                </div>
            </div>

        </el-card>
    </div>
</template>

<script>
    import { validateNull, validateEMail, validatePhone } from "@/utils/validate";
    import { uploadImage, configInfo, updateConfig } from "@/api/user";
    export default {

        name: '',
        data() {
            return {
                rule: {
                    logo: [{ validator: validateNull, trigger: "change", required: true }],

                    name: [{ validator: validateNull, trigger: "blur", required: true }],
                    version: [{ validator: validateNull, trigger: "blur", required: true }],



                    media_cooperation: [{ validator: validateNull, trigger: "blur", required: true }],
                    market_cooperation: [{ validator: validateNull, trigger: "blur", required: true }],
                    privacy_policy: [{ validator: validateNull, trigger: "blur", required: true }],
                    term_of_service: [{ validator: validateNull, trigger: "blur", required: true }],
                    disclaimers: [{ validator: validateNull, trigger: "blur", required: true }],
                    twitter: [{ validator: validateNull, trigger: "blur", required: true }],
                    telegram: [{ validator: validateNull, trigger: "blur", required: true }],
                    official_website: [{ validator: validateNull, trigger: "blur", required: true }],
                    gmail: [{ validator: validateNull, trigger: "blur", required: true }],
                    log_off: [{ validator: validateNull, trigger: "blur", required: true }],
                    advertising_cooperation: [{ validator: validateNull, trigger: "blur", required: true }],
                    desc: [{ validator: validateNull, trigger: "blur", required: true }],
                    ios_version_code: [{ validator: validateNull, trigger: "blur", required: true }],
                    ios_version_name: [{ validator: validateNull, trigger: "blur", required: true }],
                    ios_forced: [{ validator: validateNull, trigger: "change", required: true }],
                    ios_download_url: [{ validator: validateNull, trigger: "blur", required: true }],
                    ios_update_content: [{ validator: validateNull, trigger: "blur", required: true }],
                    android_version_code: [{ validator: validateNull, trigger: "blur", required: true }],
                    android_version_name: [{ validator: validateNull, trigger: "blur", required: true }],
                    android_forced: [{ validator: validateNull, trigger: "change", required: true }],
                    android_download_url: [{ validator: validateNull, trigger: "blur", required: true }],
                    android_update_content: [{ validator: validateNull, trigger: "blur", required: true }],


                },
                value: "",
                formData: {
                    logo: "",
                    name: "",
                    version: "",
                    media_cooperation: "",
                    market_cooperation: "",
                    privacy_policy: "",
                    term_of_service: "",
                    disclaimers: "",
                    twitter: "",
                    telegram: "",
                    official_website: "",
                    gmail: "",
                    log_off: "",
                    advertising_cooperation: "",
                    desc: "",
                    ios_version_code: "",
                    ios_version_name: "",
                    ios_forced: "",
                    ios_download_url: "",
                    ios_update_content: "",
                    android_version_code: "",
                    android_version_name: "",
                    android_forced: "",
                    android_download_url: "",
                    android_update_content: ""
                }
            }
        },
        created() {
            this.getUsersdetails()
        },
        methods: {
            // 获取用户详情
            getUsersdetails() {
                const that = this;
                configInfo({}).then((res) => {
                    if (res.code == 200) {
                     
                        for (let key in res.data) {
                            that.formData[key]=res.data[key].value
                        }
                        that.formData.ios_forced = that.formData.ios_forced ? that.formData.ios_forced.toString() : that.formData.ios_forced
                        that.formData.android_forced = that.formData.android_forced ? that.formData.android_forced.toString() : that.formData.android_forced

                    } else {
                        that.$message.error(res.message);
                    }
                }).catch((error) => {
                    that.$message.error(error.message);
                });
            },
            // 新增
            submit(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        const that = this;

                        updateConfig(this.formData).then((res) => {
                            if (res.code == 200) {
                                that.$message.success("操作成功！");
                                this.getUsersdetails()
                            } else {
                                that.$message.error(res.message);
                            }
                        }).catch((error) => {
                            that.$message.error(error.message);
                        });
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                })

            },
            // 上传图片
            uploadImageavatar(obj) {
                const file = obj.file
                let formdata = new FormData()
                formdata.append('file', file)
                const that = this;
                uploadImage(formdata).then(function (res) {
                    if (res.code == 200) {
                        console.log(res.data.url);
                        that.formData.logo = res.data.url
                    } else {
                        that.$message.error(res.message);
                    }
                }).catch(function (error) {
                })
            },


        }

    }
</script>

<style scoped>
    .main {
        width: calc(100% - 200px);
    }

    .add_main {
        width: 70%;
        margin: 0 auto;
    }
</style>